// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-free-tastings-js": () => import("../src/pages/free-tastings.js" /* webpackChunkName: "component---src-pages-free-tastings-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-keg-info-js": () => import("../src/pages/keg-info.js" /* webpackChunkName: "component---src-pages-keg-info-js" */),
  "component---src-pages-latest-ad-js": () => import("../src/pages/latest-ad.js" /* webpackChunkName: "component---src-pages-latest-ad-js" */)
}

